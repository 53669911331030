import { JaneDM } from '@iheartjane/dm-sdk';
import type { AppMode, JaneDMIdentifiers } from '@iheartjane/dm-sdk';
import { useMemo } from 'react';

import { config } from '@jane/shared/config';

export const dmSdkSourceMonolith = 'monolith';
export const dmSdkSourceKiosk = 'kiosk';
const dmSdkSource = [dmSdkSourceMonolith, dmSdkSourceKiosk] as const;
export type DmSdkSource = typeof dmSdkSource[number];

interface UseDmSdkProps {
  appMode: AppMode;
  identifier?: JaneDMIdentifiers;
  source?: DmSdkSource;
}

export const useDmSdk = (props: UseDmSdkProps): JaneDM => {
  const { appMode, identifier, source = dmSdkSourceMonolith } = props;
  const { dmSdkApiKey, dmEndpoint } = config;

  return useMemo(
    () =>
      new JaneDM({
        apiKey: dmSdkApiKey,
        appMode,
        endpoint: dmEndpoint,
        ...(identifier && { identifier }),
        source,
      }),
    [JSON.stringify(identifier)]
  );
};
