import type { SmartSortPlacement } from '@iheartjane/dm-sdk';
import { placementMenuInlineTable } from '@iheartjane/dm-sdk';
import isEmpty from 'lodash/isEmpty';

import type {
  AlgoliaProduct,
  CurrentSort,
  JaneSearchState,
} from '@jane/search/types';
import { makeCurrentSort } from '@jane/search/types';
import { byAToZDefault, byRecommendedDefault } from '@jane/search/util';
import { config } from '@jane/shared/config';
import { ObjectEntries } from '@jane/shared/util';

import type { FetchInlineAdParams } from '../../data-access/fetchInlineAd';
import type { InlinePlacement } from '../../types/placement';

interface Props {
  filterSearchState: Pick<
    JaneSearchState<AlgoliaProduct>,
    'bucketFilters' | 'currentSort' | 'filters' | 'rangeFilters' | 'searchText'
  >;
  isRecommendedSortEnabled: boolean;
  optionalFilters?: string;
  placement: InlinePlacement;
}
export const buildFetchInlineAdsSearchFilterParam = ({
  filterSearchState,
  isRecommendedSortEnabled,
  placement,
  optionalFilters,
}: Props): FetchInlineAdParams['searchFilter'] => {
  const { algoliaEnv } = config;
  const { bucketFilters, currentSort, filters, searchText, rangeFilters } =
    filterSearchState;
  const sort = getSort(currentSort, placement, isRecommendedSortEnabled);

  const latestAddedAggregateRating = bucketFilters?.aggregate_rating?.[0] ?? '';
  const latestAddedBucketPrice = bucketFilters?.bucket_price?.[0] ?? '';

  return {
    index: buildIndex(algoliaEnv, sort),
    optional_filters: optionalFilters,
    query: searchText,
    range: removeEmptyFields({
      aggregate_rating: latestAddedAggregateRating,
      bucket_price: latestAddedBucketPrice,
      percent_cbd: rangeFilters?.percent_cbd ?? {},
      percent_thc: rangeFilters?.percent_thc ?? {},
      percent_thca: rangeFilters?.percent_thca ?? {},
    }),
    refinement_list: removeEmptyFields({
      activities: filters?.activities ?? [],
      applicable_special_ids: filters?.applicable_special_ids ?? [],
      available_weights: filters?.available_weights ?? [],
      brand_subtypes: filters?.brand_subtype ?? [],
      brands: filters?.brand ?? [],
      categories: filters?.category ?? [],
      feelings: filters?.feelings ?? [],
      has_brand_discount: filters?.has_brand_discount ?? [],
      product_ids: filters?.product_id ?? [],
      root_types: filters?.root_types ?? [],
    }),
    sort_by: sort.id,
  };
};

const getSort = (
  currentSort: CurrentSort | undefined,
  placement: SmartSortPlacement,
  isRecommendedSortEnabled: boolean
): CurrentSort => {
  const defaultSortForCarousel = makeCurrentSort(byAToZDefault);
  const defaultSortForTable = isRecommendedSortEnabled
    ? makeCurrentSort(byRecommendedDefault)
    : makeCurrentSort(byAToZDefault);
  const defaultSort =
    placement === placementMenuInlineTable
      ? defaultSortForTable
      : defaultSortForCarousel;
  return currentSort ?? defaultSort;
};

const buildIndex = (
  algoliaEnv: string,
  currentSort: CurrentSort | undefined
): string => {
  const prefix = 'menu-products-';
  const suffix = currentSort?.suffix || '';

  return `${prefix}${suffix}${algoliaEnv}`;
};

const removeEmptyFields = (object: Record<string, any>) =>
  ObjectEntries(object).reduce((acc: Record<string, any>, [key, value]) => {
    if (!isEmpty(value)) {
      acc[key] = value;
    }
    return acc;
  }, {});
