export function buildBooleanFilter(column: string, value?: boolean) {
  return typeof value === 'boolean' ? `${column}:${value}` : '';
}

export function buildFilter(column: string, values?: string[] | number[]) {
  return values && values.length > 0
    ? `(${values
        .map((value) => {
          if (typeof value === 'number') return `${column}:${value}`;
          return `${column}:"${value.replace(/"/g, '\\"')}"`;
        })
        .join(' OR ')})`
    : '';
}

export function buildBucketFilter(column: string, values?: string[]) {
  return values && values.length > 0
    ? `(${values
        .map((value) => {
          const [start, end] = value.split(':');

          if (!start) {
            return `${column} <= ${end}`;
          } else if (!end) {
            return `${column} >= ${start}`;
          } else {
            return `${column}:${start} TO ${end}`;
          }
        })
        .join(' OR ')})`
    : '';
}

export function buildRangeFilter(column: string, min?: number, max?: number) {
  return min || max
    ? `(${[!!min && `${column} >= ${min}`, !!max && `${column} <= ${max}`]
        .filter(Boolean)
        .join(' AND ')})`
    : '';
}

export function composeFilters(
  ...filters: Array<string | null | undefined>
): string {
  return filters.filter(Boolean).map(wrapParensIfNeeded).join(` AND `);
}

function wrapParensIfNeeded(filter: string | null | undefined) {
  if (!filter) {
    return '';
  }

  const trimmedFilter = filter.trim();
  if (
    /OR/.test(trimmedFilter) &&
    !/AND/.test(trimmedFilter) &&
    !trimmedFilter.startsWith('(') &&
    !trimmedFilter.endsWith(')')
  ) {
    return `(${trimmedFilter})`;
  }
  return filter;
}
