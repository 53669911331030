import type { ReactNode } from 'react';
import { useContext } from 'react';

import {
  Box,
  DismissIcon,
  Flex,
  useMobileMediaQuery,
} from '@jane/shared/reefer';

import { PromptContext } from './prompt';
import { PromptArrow } from './prompt.styles';

export const PromptContent = ({ children }: { children: ReactNode }) => {
  const { dismissible, dismissPrompt } = useContext(PromptContext);
  const isMobile = useMobileMediaQuery({});
  return (
    <Flex
      border="grays-light"
      background="grays-white"
      borderRadius={isMobile ? 'sm' : 'lg'}
      p={isMobile ? 16 : 24}
      alignItems="center"
      data-testid="prompt-content"
    >
      {/* The "Arrow" is a diamond and this Box hides the bottom half */}
      <Box
        overflow="hidden"
        position="absolute"
        top={-6.5}
        right={isMobile ? 25 : 30}
        height={7.5}
        width={15}
        zIndex="var(--layers-popover)"
      >
        <PromptArrow />
      </Box>
      {dismissible && (
        <DismissIcon
          mr={isMobile ? 16 : 24}
          altText="dismiss login prompt"
          onClick={dismissPrompt}
          size={isMobile ? 'lg' : 'md'}
        />
      )}
      {children}
    </Flex>
  );
};
