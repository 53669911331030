import { placementPDPRow } from '@iheartjane/dm-sdk';

import { useSmartRecommendedPDPRow } from '@jane/dm/internal';
import type { CardCarouselProps } from '@jane/shared/components';
import { CardCarousel } from '@jane/shared/components';
import { config } from '@jane/shared/config';
import type { AppMode, MenuProduct, Store } from '@jane/shared/models';
import { ZoneStorePdp } from '@jane/shared/models';
import { useMobileMediaQuery } from '@jane/shared/reefer';

import { MenuProductCarousel } from '../menuProductCarousel';
import type { ProductCardWithTrackingProps } from '../productCardWithTracking';
import { ProductCardWithTracking } from '../productCardWithTracking';

type ProductDetailsSponsoredCarouselProps = Pick<CardCarouselProps, 'mode'> &
  Pick<
    ProductCardWithTrackingProps,
    'cartProducts' | 'cartIsOpen' | 'cartStoreId'
  > & {
    appMode: AppMode;
    attemptToAddToCart: (...args: unknown[]) => void;
    onCloseCart: (...args: unknown[]) => void;
    onDeleteFromCart: (...args: unknown[]) => void;
    productId: number;
    store: Store;
  };

export const ProductDetailsSponsoredCarousel = (
  props: ProductDetailsSponsoredCarouselProps
) => {
  const {
    appMode,
    productId,
    store,
    cartProducts,
    cartIsOpen,
    cartStoreId,
    mode = 'ideal-width',
    attemptToAddToCart,
    onCloseCart,
    onDeleteFromCart,
  } = props;
  const isMobile = useMobileMediaQuery({});

  const { products, isLoading, instance } = useSmartRecommendedPDPRow({
    appMode,
    productId,
    searchAttributes: ['*'],
    storeId: Number(store.id),
  });

  const rowName = instance?.title || 'You may also like';

  if (!isLoading && (!instance || products.length === 0)) {
    return <></>;
  }

  return (
    <MenuProductCarousel
      isLoading={isLoading}
      name={rowName}
      mode={mode}
      listView={false}
      variant="sponsored"
      mx={isMobile ? 24 : 64}
      mb={24}
    >
      {products.map((product, index) => (
        <CardCarousel.Card
          key={`row-ad-${product.objectId}`}
          width="100%"
          widthMobile="100%"
        >
          <ProductCardWithTracking
            algoliaIndexName={`menu-products-${config.algoliaEnv}`}
            appliedWeightFilter=""
            carouselView={true}
            columnPosition={index}
            menuRowName={rowName}
            productLocation={'PDP'}
            product={product.attributes as unknown as MenuProduct}
            productInstance={product}
            rowPosition={0}
            zone={ZoneStorePdp}
            listView={false}
            cartIsOpen={cartIsOpen}
            cartProducts={cartProducts}
            store={store}
            appMode={appMode}
            attemptToAddToCart={attemptToAddToCart}
            closeCart={onCloseCart}
            deleteCartItem={(item, priceId) => {
              onDeleteFromCart(item, priceId);
            }}
            cartStoreId={cartStoreId}
            placement={placementPDPRow}
          />
        </CardCarousel.Card>
      ))}
    </MenuProductCarousel>
  );
};
