import type { AlgoliaProduct } from '@jane/search/types';
import type { AdFlight, DmMeta } from '@jane/shared/models';

import type { AdRow } from '../../data-access/fetchInlineAd';
import { trackAdsServerProductMissingInAlgolia } from '../../mixpanel/events';

export const buildSortedProductsWithFlights = (
  sortedRows: AdRow[],
  flights: AdFlight[],
  products: AlgoliaProduct[] = []
): AlgoliaProduct[] => {
  if (products.length === 0) {
    return [];
  }
  const productsMap: Record<string, AlgoliaProduct> = {};
  products.forEach((product) => {
    productsMap[product.product_id] = product;
  });

  const flightsMap: Record<string, AdFlight> = {};
  flights?.forEach((flight) => {
    flightsMap[flight.id] = flight;
  });

  const sortedProducts: AlgoliaProduct[] = sortedRows
    .map((row) => {
      const { ad_token, flight_id, product_id, my_high_d } = row;
      const product = productsMap[product_id];
      if (!product) {
        trackAdsServerProductMissingInAlgolia({
          adToken: ad_token,
          flightId: flight_id,
          productId: product_id,
        });
        return undefined;
      }

      if (ad_token || my_high_d) {
        const dmMeta: DmMeta = {};
        if (ad_token) {
          dmMeta.adToken = ad_token;
        }
        if (my_high_d) {
          dmMeta.myHighD = my_high_d;
        }
        // algolia returns Algolia products, but we need MenuProduct to add `dmMeta` to it.
        // cant cast to MenuProduct and set dmMeta since MenuProduct is DeepReadonly
        (product as any).dmMeta = dmMeta;
      }

      if (flight_id) {
        // algolia returns Algolia products, but we need MenuProduct to add `flight` to it.
        // cant cast to MenuProduct and set flight since MenuProduct is DeepReadonly
        (product as any).flight = flightsMap[flight_id];
      }
      return product;
    })
    .filter((product): product is AlgoliaProduct => product !== undefined);
  return sortedProducts;
};
