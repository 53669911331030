import { useState } from 'react';

import { useEcommApp } from '@jane/shared/providers';
import { Modal } from '@jane/shared/reefer';

import { SignInToggleButtons } from './components/signInToggleButtons';
import { CreateAccount } from './createAccount/createAccount';
import type { CreateAccountData } from './createAccount/createAccount';
import { ForgotPassword } from './forgotPassword/forgotPassword';
import type { EmailFormData } from './forgotPassword/forgotPassword';
import { SignIn } from './signIn/signIn';
import type { SignInFormData } from './signIn/signIn';

export type ScreenName = 'sign-in' | 'create-account' | 'forgot-password';

interface AuthenticationErrors {
  login?: string;
  register?: string[];
  resetPassword?: string;
}
export type JaneAuthenticationErrors = AuthenticationErrors | null;

export interface JaneAuthenticationProps {
  /** Errors from logging in, registering, or attempting a password reset. */
  errors: JaneAuthenticationErrors;

  logOut?(): void;

  /** Function called to initiate Apple log in */
  onAppleClick(): void;

  /** Function called when sign up button is clicked on Create Account screen */
  onCreateAccountSubmit(data: CreateAccountData): void;

  /** Function called when email is submitted to Forgot Password Screen, returns errors if any */
  onForgotPasswordSubmit(data: EmailFormData): Promise<boolean>;

  /** Function called to initiate Google log in */
  onGoogleClick(): void;

  /** Function called when sign in button is clicked on Sign In screen */
  onSignInSubmit(data: SignInFormData): void;

  /** Boolean values checking Boost menu configuration */
  showAppleLogin: boolean;
  showGoogleLogin: boolean;
}

export const JaneAuthentication = ({
  errors,
  logOut,
  onSignInSubmit,
  onAppleClick,
  onCreateAccountSubmit,
  onGoogleClick,
  onForgotPasswordSubmit,
  showAppleLogin,
  showGoogleLogin,
}: JaneAuthenticationProps) => {
  const [screen, setScreen] = useState<ScreenName>('sign-in');
  const [hasResetPassword, setHasResetPassword] = useState(false);

  const { appMode } = useEcommApp();

  const handleForgotPassword = async (email: EmailFormData) => {
    const success = await onForgotPasswordSubmit(email);
    if (success) {
      setHasResetPassword(true);
      setScreen('sign-in');
    }
  };

  const handleSetScreen = (screen: ScreenName) => setScreen(screen);

  return (
    <>
      {screen !== 'forgot-password' && (
        <>
          <SignInToggleButtons screen={screen} setScreen={handleSetScreen} />
          <Modal.ContentDivider />
        </>
      )}

      {screen === 'sign-in' && (
        <SignIn
          error={errors?.login || null}
          logOut={logOut}
          onSubmit={onSignInSubmit}
          onAppleClick={onAppleClick}
          onGoogleClick={onGoogleClick}
          onForgotPassword={() => setScreen('forgot-password')}
          resetPassword={hasResetPassword}
          showAppleLogin={showAppleLogin}
          showGoogleLogin={showGoogleLogin}
        />
      )}
      {screen === 'create-account' && (
        <CreateAccount
          errors={errors?.register || null}
          isMarketplace={appMode === 'default'}
          onSubmit={onCreateAccountSubmit}
          onAppleClick={onAppleClick}
          onGoogleClick={onGoogleClick}
          showAppleLogin={showAppleLogin}
          showGoogleLogin={showGoogleLogin}
        />
      )}
      {screen === 'forgot-password' && (
        <ForgotPassword
          error={errors?.resetPassword || null}
          onSubmit={handleForgotPassword}
          onBackClick={() => setScreen('sign-in')}
          onDoneClick={() => setScreen('sign-in')}
        />
      )}
    </>
  );
};
