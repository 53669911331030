// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone';
// eslint-disable-next-line no-restricted-imports
import type { MomentFormatSpecification } from 'moment-timezone';

export function convertHourMinuteToMoment(hour: number, minute: number) {
  return moment.tz().hour(hour).minute(minute).second(0).millisecond(0);
}

export function currentTime(timezone: string) {
  return moment.tz(timezone);
}

export function isBefore(date: string): boolean {
  return moment().isBefore(date);
}

export function parseWithTimezone(
  date: string,
  timezone: string,
  inputFormat?: MomentFormatSpecification
) {
  if (inputFormat) return moment.tz(date, inputFormat, timezone);
  return moment.tz(date, timezone);
}
