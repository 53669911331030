import type { MouseEventHandler } from 'react';

export interface AccessibilityProps {
  /** Provides a text alternative for elements that have no visible text  */
  'aria-label'?: string;

  /** Provides a a reference to a text alternative for elements that have no visible text  */
  'aria-labelledby'?: string;

  /** Provides a text alternative for elements that have no visible text  */
  ariaLabel?: string;

  /** Provides a a reference to a text alternative for elements that have no visible text  */
  ariaLabelledBy?: string;

  /** Sets the role of the element */
  role?: string;
}

/**
 * Check accessibility for components that have action-related roles.
 * Return object of accessibility props, mapped to their appropriate html attributes.
 *
 * See: https://dequeuniversity.com/rules/axe/4.1/aria-command-name
 */
export function getAccessibilityProps({
  ariaLabel,
  ariaLabelledBy,
  role: roleProp,
  onClick,
}: AccessibilityProps & { onClick?: MouseEventHandler }) {
  const role = onClick ? 'button' : roleProp;

  return {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    role,
  };
}
