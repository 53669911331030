import algoliaInsights from 'search-insights';

import { config } from '@jane/shared/config';

export default () => {
  algoliaInsights('init', {
    apiKey: config.algoliaApiKey,
    appId: config.algoliaAppId,
    // This api initializer is different from search api when it comes to initializing hosts
    // it's not an array and it needs the scheme specified
    host: 'https://' + config.algoliaUrl,
  });
};
