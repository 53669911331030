import styled from '@emotion/styled';
import { useCallback, useEffect, useRef } from 'react';
import type { MutableRefObject } from 'react';

import { useShouldShowGold } from '@jane/shared/hooks';
import type { ReservationCartProduct } from '@jane/shared/models';
import {
  Card,
  ConditionalWrapper,
  Flex,
  Typography,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import {
  getJaneGoldTagLabel,
  getSpecialTagLabel,
  productPhotoMetadata,
} from '@jane/shared/util';

import { JaneGoldTag } from '../janeGoldTag';
import {
  DisplayMode,
  useProductCardContext,
} from '../productCardProvider/productCardProvider';
import { SpecialTag } from '../specialTag';
import ProductInfo from './menuProductInfo';
import { initialCountForProduct } from './utils/initialCountForProduct';
import type { LinkProps, ProductCardMode } from './utils/productCardHelper';
import { ProductDetailPageLink } from './utils/productCardHelper';

export interface Props {
  height?: number | string;
  itemWidth?: number | null;
  numberOfVisibleItems?: number;
  productCardMode?: ProductCardMode;
  selectedQuantity?: number;
  setProductCardWidth?: (arg: number) => void;
  shoppingDisabled: boolean;
}

const Count = styled(Flex)({
  borderRadius: '50%',
});

const CountNumber = styled(Typography)({
  lineHeight: '20px',
});

const UpdateCount = ({ count }: { count: number }) => (
  <Count
    as="span"
    alignItems="center"
    justifyContent="center"
    background="primary"
    height={20}
    width={20}
    inline
    ml={8}
  >
    <CountNumber color="text-inverse" variant="mini-bold">
      {count}
    </CountNumber>
  </Count>
);

export const MenuProductCardDefaultView = ({
  height,
  itemWidth,
  setProductCardWidth,
  shoppingDisabled,
}: Props) => {
  const {
    appMode,
    brand,
    breadcrumbs,
    cartIsOpen,
    cartProduct,
    currentSpecial,
    defaultWeight,
    disableInteraction,
    fromSpecialId,
    fromAllSpecials,
    hideActions,
    janeGoldLabel,
    menuProduct: product,
    onCloseCart,
    onSetBreadcrumbs,
    pdpPath,
    routeAppMode,
    routePartnerHostedConfig,
    searchState,
    setDisplayMode,
    showOnlyWeights,
    store,
  } = useProductCardContext();
  const isMobile = useMobileMediaQuery({});
  const { url } = productPhotoMetadata(product);
  const shouldShowGold = useShouldShowGold({ product, store });
  const containerRef: MutableRefObject<HTMLDivElement | undefined> =
    useRef<HTMLDivElement>();

  useEffect(() => {
    setProductCardWidth &&
      containerRef.current &&
      setProductCardWidth(
        itemWidth || containerRef.current.getBoundingClientRect().width
      );
  }, []);

  const count = initialCountForProduct(cartProduct as ReservationCartProduct[]);

  const specialTagLabel =
    store &&
    product &&
    getSpecialTagLabel({
      defaultWeight,
      product,
      store,
    });

  const specialApplies = specialTagLabel && currentSpecial;

  const productDetailPageLinkProps = {
    appMode,
    brand,
    breadcrumbs,
    cartIsOpen,
    product,
    searchState,
    store,
    fromMenu: true,
    fromAllSpecials,
    fromSpecialId,
    onCloseCart,
    onSetBreadcrumbs,
    pdpPath,
    routeAppMode,
    routePartnerHostedConfig,
    bundleSpecialId:
      currentSpecial?.special_type === 'bundle' ? currentSpecial.id : undefined,
  } as Omit<LinkProps, 'children'>;

  const hasMultipleWeights =
    showOnlyWeights?.length !== 1 &&
    (product?.available_weights || []).length > 1;

  const addToClickHandler = useCallback(() => {
    !disableInteraction &&
      !shoppingDisabled &&
      setDisplayMode(DisplayMode.Edit);
  }, [shoppingDisabled, setDisplayMode]);

  const janeGoldTagLabel = shouldShowGold
    ? janeGoldLabel ||
      (product &&
        getJaneGoldTagLabel({
          menuProduct: product,
        }))
    : false;

  return (
    <Card width="100%" height={height}>
      <Flex
        flexWrap="wrap"
        gap={4}
        justifyContent="flex-end"
        p={8}
        position="absolute"
        width="100%"
        zIndex="var(--layers-visible)"
        css={{ pointerEvents: 'none' }}
      >
        {janeGoldTagLabel && <JaneGoldTag label={janeGoldTagLabel} />}
        {specialApplies && <SpecialTag label={specialTagLabel} />}
      </Flex>
      <ConditionalWrapper
        condition={!disableInteraction}
        wrapper={(children) => (
          <ProductDetailPageLink height="100%" {...productDetailPageLinkProps}>
            {children}
          </ProductDetailPageLink>
        )}
      >
        <Flex
          ariaLabel={`view menu product details for ${product?.name}`}
          height="100%"
          flexDirection="column"
        >
          <Card.Image
            alt={product?.name || ''}
            height={isMobile ? '150px' : '200px'}
            responsive
            sizes="card"
            src={url}
            data-testid="menu-product-card default-view"
          />
          <ProductInfo currentSpecial={currentSpecial} />
        </Flex>
      </ConditionalWrapper>
      {!hideActions && (
        <Card.Action
          data-testid="menu-product-card-cta"
          ariaLabel={
            shoppingDisabled
              ? 'shopping disabled'
              : (cartProduct || []).length > 0
              ? `update bag button for ${product?.name}`
              : `add to bag button for ${product?.name}`
          }
          disabled={shoppingDisabled}
          endIcon={
            !shoppingDisabled &&
            (cartProduct || []).length > 0 && <UpdateCount count={count} />
          }
          onClick={addToClickHandler}
          label={
            shoppingDisabled
              ? 'Shopping disabled'
              : (cartProduct || []).length > 0
              ? 'Update'
              : hasMultipleWeights
              ? 'Select weight'
              : 'Add to bag'
          }
          variant="secondary"
        />
      )}
    </Card>
  );
};
