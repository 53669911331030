import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { DayJs, DayJsOptionType, Time } from '@jane/shared/types';

import { parseApiTimezones } from '../timezones';

dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export function formatTime(
  time: DayJs | Time,
  outputFormat?: string,
  inputFormat?: DayJsOptionType,
  timezone?: string
): string {
  if (timezone) {
    return dayjs(time, inputFormat)
      .tz(parseApiTimezones(timezone))
      .format(outputFormat);
  }
  return dayjs(time, inputFormat).format(outputFormat);
}

export function formatCurrentTime(
  outputFormat?: string,
  timezone?: string
): string {
  if (timezone) {
    return dayjs().tz(parseApiTimezones(timezone)).format(outputFormat);
  }
  return dayjs().format(outputFormat);
}

export function formatUtcTime(
  time: DayJs | Time,
  outputFormat?: string,
  inputFormat?: string
): string {
  return dayjs.utc(time, inputFormat).format(outputFormat);
}

export function formatWithCalendar(
  time: DayJs | Time,
  timezone: string,
  calendarFormats?: object,
  inputFormat?: DayJsOptionType
): string {
  let dayjsTime;
  if (timezone) {
    dayjsTime = dayjs(time, inputFormat).tz(parseApiTimezones(timezone));
  } else {
    dayjsTime = dayjs(time, inputFormat);
  }
  return dayjsTime.calendar(dayjs(), calendarFormats);
}
