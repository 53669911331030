import styled from '@emotion/styled';

import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

import type {
  ActiveCarouselThumbnailProps,
  StyledThumbnailWrapperProps,
  ThumbnailRowWrapperProps,
} from '../carousel.types';

export const ThumbnailsWrapper = styled.div<StyledThumbnailWrapperProps>(
  ({ isLightbox }) => ({
    display: 'none',
    [mediaQueries.tablet('min')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...spacing({ pt: isLightbox ? 40 : 24 }),
    },
  })
);

export const ThumbnailRowWrapper = styled.div<ThumbnailRowWrapperProps>(
  ({ isLightbox, maxWidth }) => ({
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none' /** Chrome */,
    },
    scrollbarWidth: 'none' /** Firefox */,
    ' -ms-overflow-style': 'none' /** IE */,
    maxWidth: isLightbox ? '100vw' : `calc(${maxWidth} - 120px)`,
    ...spacing({ pb: 12 }),
  })
);

export const StyledCarouselThumbnail = styled.div<ActiveCarouselThumbnailProps>(
  ({ theme, selected }) => ({
    border: `2px solid ${selected ? theme.colors.primary.main : 'transparent'}`,
    borderRadius: theme.borderRadius.lg,
    backgroundColor: selected ? theme.colors.primary.main : 'transparent',
    overflow: 'hidden',
    backfaceVisibility: 'hidden',
    transform: 'translate3d(0, 0, 0)',
    display: 'inline-block',
    height: '88px',
    width: '88px',
    cursor: 'pointer',
    ...spacing({ mx: 12 }),
    ' > div': {
      top: '-3px',
      left: '-3px',
      bottom: '-3px',
      right: '-3px',
    },
  })
);

export const ThumbnailButtonWrapper = styled.div({
  zIndex: 'var(--layers-visible)',
  ...spacing({ pb: 12 }),
});
