import styled from '@emotion/styled';

import { Box, Flex } from '@jane/shared/reefer';
import { mediaQueries } from '@jane/shared/reefer-emotion';

export const StyledPdpContainer = styled(Flex)<{ onMenu: boolean }>(
  ({ onMenu }) => ({
    flexDirection: 'column',
    width: '100%',
    gap: 16,

    [mediaQueries.tablet('min')]: {
      textAlign: 'left',
      flexDirection: onMenu ? 'column' : 'row',
      justifyContent: 'space-between',
    },
  })
);

export const StyledPdpInnerContainer = styled(Flex)({
  flexDirection: 'column',
  width: '100%',

  [mediaQueries.tablet('min')]: {
    flexDirection: 'row',
    marginRight: 16,
  },
});

export const StyledPdpTitleDescriptionWrapper = styled(Flex)({
  width: '100%',

  [mediaQueries.tablet('min')]: {
    alignItems: 'flex-start',
    marginTop: 0,
  },
});

export const StyledPdpDescriptionWrapper = styled(Box)({
  [mediaQueries.tablet('min')]: {
    maxWidth: '100%',
  },
});
