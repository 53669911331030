import type { CSSObject } from '@emotion/react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import exclamationIcon from 'shared/assets/icons/exclamation.svg';

import { effects } from '@jane/shared/reefer';

import { useCustomerSelector } from '../../customer/selectors';
import { get } from '../../redux-util/selectors';
import { useHideNotifications } from './useHideNotifications';

interface NotificationContainerProps {
  notificationColor: string;
  styleOverrides: any;
}

const NotificationContainer = styled.div<NotificationContainerProps>(
  ({ theme }) => ({
    display: 'flex',
    position: 'fixed',
    top: '15px',
    right: '25px',
    width: '240px',
    transition: 'all 0.5s ease-in-out',
    borderRadius: '6px',
    fontSize: '13px',
    lineHeight: '1.3m',
    boxShadow: `0 1px 5px 0 ${effects.lighten('#000', 0.5)}`,
    zIndex: 'var(--layers-toast)',
    color: theme.colors.grays.white,
  }),
  ({ notificationColor }) => ({
    backgroundColor: notificationColor,
  }),
  ({ styleOverrides }) => ({
    ...(styleOverrides && {
      ...styleOverrides,
    }),
  })
);

const NotificationInner = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '11px 10px',
});

const NotificationsExclamation = styled.div({
  width: '36px',
  height: '36px',
  marginRight: '12px',
  backgroundImage: `url("${exclamationIcon}")`,
});

const NotificationsText = styled.div({
  flex: 1,
});

const ServerNotifications = ({
  styleOverrides,
}: {
  styleOverrides?: CSSObject;
}) => {
  const hideNotifications = useHideNotifications();

  const theme = useTheme();
  const { notificationOptions, notificationShown } = useCustomerSelector(
    get('application')
  );
  const renderNotificationByType = useMemo(() => {
    const { type, messages } = notificationOptions;

    if (!notificationShown || !messages || hideNotifications) return null;

    const notificationColor =
      type === 'error'
        ? theme.colors.system.negative.main
        : theme.colors.system.positive.main;

    return messages.map((message, index) => (
      <CSSTransition key={index} classNames="notifications" timeout={500}>
        <NotificationContainer
          aria-live="polite"
          key={index}
          notificationColor={notificationColor}
          styleOverrides={styleOverrides}
        >
          <NotificationInner>
            <NotificationsExclamation />
            <NotificationsText>{message}</NotificationsText>
          </NotificationInner>
        </NotificationContainer>
      </CSSTransition>
    ));
  }, [notificationOptions]);

  return <TransitionGroup>{renderNotificationByType}</TransitionGroup>;
};

export default ServerNotifications;
