import { useNavigate } from 'react-router-dom';

import { SpecialTag } from '@jane/shared/components';
import {
  useNeedsSignInForEligibility,
  useShouldShowGold,
} from '@jane/shared/hooks';
import type { PendingCartProduct, Store } from '@jane/shared/models';
import {
  Box,
  Card,
  Flex,
  Image,
  JaneGoldIcon,
  Typography,
  useDrawerContext,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import {
  calculateDiscountPercentage,
  formatCurrency,
  postMessageToIframeParent,
  productPhotoMetadata,
} from '@jane/shared/util';

import { useCustomerDispatch } from '../../../../customer/dispatch';
import { clearTouchedProduct } from '../../../../customer/redux/bundlePossibilities';
import { deleteCartItem, updateCart } from '../../../../customer/redux/cart';
import { useCustomerSelector } from '../../../../customer/selectors';
import { displayQuantity } from '../../../../lib/receipt';
import { get } from '../../../../redux-util/selectors';
import { CartDrawerItemActions } from './cartDrawerItemActions';
import {
  CartDrawerItem,
  ProductDetailsContainer,
  StyledTypography,
} from './cartDrawerItems.styles';
import { SpecialsTag } from './specialsTag';

interface CartDrawerCardProps {
  bloomUserExperience?: boolean;
  headless?: boolean;
  product: PendingCartProduct;
  productDetailLink: string;
  productFullPrice: number;
  productPrice: number | null;
}

export const CartDrawerCard = ({
  bloomUserExperience,
  headless,
  product,
  productDetailLink,
  productPrice,
  productFullPrice,
}: CartDrawerCardProps) => {
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery({});
  const dispatch = useCustomerDispatch();
  const { store } = useCustomerSelector(get('store'));

  const { onRequestClose } = useDrawerContext();

  const {
    amount,
    brand,
    brand_discount_label,
    brand_discount_title,
    brand_subtype,
    category,
    count,
    id,
    max_cart_quantity,
    name,
    price_id,
    special_title,
  } = product;
  const brandDiscountText = brand_discount_title || brand_discount_label;

  const shouldShowGold =
    useShouldShowGold({ product, store: store as Store }) &&
    !!brandDiscountText;

  const needsSignInForEligibility = useNeedsSignInForEligibility({
    cartProduct: product,
  });

  const maxCartQuantity = product[`max_cart_quantity_${price_id}`]
    ? product[`max_cart_quantity_${price_id}`]
    : max_cart_quantity;

  const removeProduct = () => {
    dispatch(deleteCartItem(product.id, product.price_id));
  };

  const editProductQuantity = (newCount: number) => {
    dispatch(clearTouchedProduct());
    if (count !== newCount) {
      if (headless) {
        postMessageToIframeParent({
          messageType: 'analyticsEvent',
          payload: {
            name: 'cartItemChangeCount',
            properties: {
              productId: id,
              priceId: price_id,
              count: newCount,
            },
          },
        });
      }
      dispatch(
        updateCart({
          product_id: id,
          count: newCount,
          price_id,
        })
      );
    }
  };

  const { url: photoUrl } = productPhotoMetadata(product);

  const showWeight = price_id !== 'each' && !amount;
  const weightString = showWeight ? `/${displayQuantity(product)}` : '';

  const goToProductDetailPage = () => {
    if (headless) {
      postMessageToIframeParent({
        messageType: 'analyticsEvent',
        payload: {
          name: 'cartItemClickedInCheckout',
          properties: {
            productId: product.id,
            priceId: product.price_id,
            productCount: product.count,
            price: productPrice,
          },
        },
      });
    }
    onRequestClose();
    navigate(productDetailLink);
  };

  const isDiscounted = productFullPrice !== productPrice;

  const discountPercentage = calculateDiscountPercentage(
    productFullPrice,
    productPrice
  );

  const price = bloomUserExperience ? null : (
    <Typography variant="body-bold" textAlign="right">
      {formatCurrency(productPrice * count)}
    </Typography>
  );

  const productDetails = (
    <ProductDetailsContainer
      onClick={goToProductDetailPage}
      ariaLabel="product-detail"
      mt={isMobile ? 16 : undefined}
      width="100%"
      overflow="hidden"
      flexDirection="column"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Typography truncateAt={isMobile ? '100%' : '85%'} variant="body-bold">
          {name}
        </Typography>
        {!isMobile && price}
      </Flex>
      <StyledTypography color="text-light">{brand}</StyledTypography>
      <StyledTypography color="text-light">
        {brand_subtype}
        {brand_subtype && category ? ' - ' : ''}
        {category}
      </StyledTypography>
      <StyledTypography color="text-light">
        {formatCurrency(productPrice)}
        {weightString}
      </StyledTypography>

      {special_title && (!bloomUserExperience || isDiscounted) && (
        <SpecialsTag
          title={special_title}
          bloomUserExperience={bloomUserExperience}
        />
      )}
      {shouldShowGold && (
        <Flex alignItems="flex-start" gap={4} width="100%">
          <Box mt={4}>
            <JaneGoldIcon altText="jane-gold-icon" size="sm" />
          </Box>
          <Typography>
            {brandDiscountText}{' '}
            {needsSignInForEligibility && (
              <Typography as="span" color="grays-mid">
                (Sign in for eligibility)
              </Typography>
            )}
          </Typography>
        </Flex>
      )}
    </ProductDetailsContainer>
  );

  return (
    <CartDrawerItem data-testid="cart-item" flat width="100%">
      <Card.Content>
        <Flex flexDirection="column" height="100%">
          <Flex
            flexDirection="row"
            height="100%"
            width="100%"
            minWidth={isMobile ? 'calc(95vw - 80px)' : ''}
            justifyContent={isMobile && 'space-between'}
            gap={16}
          >
            <Flex flexDirection="column" width={isMobile ? '100%' : undefined}>
              <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                width="100%"
              >
                <ProductDetailsContainer
                  onClick={goToProductDetailPage}
                  ariaLabel="product-image"
                  alignSelf={isMobile ? 'flex-start' : ''}
                >
                  {bloomUserExperience ? (
                    <Flex
                      width={isMobile ? '88px' : '96px'}
                      height={isMobile ? '88px' : '96px'}
                    >
                      <Flex position="absolute">
                        <Image
                          src={photoUrl}
                          altText={name}
                          border
                          borderRadius={isMobile ? '16px' : 'rounded'}
                          height={isMobile ? '88px' : '96px'}
                          width={isMobile ? '88px' : '96px'}
                          responsive
                          sizes="thumbnail"
                        />
                      </Flex>
                      {discountPercentage > 0 && (
                        <Flex
                          zIndex={1}
                          position="relative"
                          width="100%"
                          justifyContent="flex-end"
                          p={8}
                        >
                          <SpecialTag label={`-${discountPercentage}%`} />
                        </Flex>
                      )}
                    </Flex>
                  ) : (
                    <Image
                      src={photoUrl}
                      altText={name}
                      border
                      borderRadius={isMobile ? '16px' : 'rounded'}
                      height="72px"
                      width="72px"
                      responsive
                      sizes="thumbnail"
                    />
                  )}
                </ProductDetailsContainer>
                {isMobile && price}
              </Flex>

              {isMobile && productDetails}
            </Flex>

            {!isMobile && (
              <Flex width="100%" overflow="hidden">
                {productDetails}
              </Flex>
            )}

            <Flex
              flexDirection="column"
              justifyContent="space-between"
              shrink={bloomUserExperience}
            >
              {bloomUserExperience ? (
                <Flex
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  flexWrap="wrap"
                >
                  <Typography
                    variant="body-bold"
                    textAlign="right"
                    ml={8}
                    color={
                      isDiscounted && !bloomUserExperience ? 'info' : 'inherit'
                    }
                  >
                    {formatCurrency(productPrice * count)}
                  </Typography>
                  {isDiscounted && (
                    <Typography
                      variant={bloomUserExperience ? 'body' : 'body-bold'}
                      textAlign="right"
                      strikeThrough
                      color="text-light"
                      ml={8}
                    >
                      {formatCurrency(productFullPrice * count)}
                    </Typography>
                  )}
                </Flex>
              ) : null}
            </Flex>
          </Flex>
          <Flex width="100%" mt={24}>
            <CartDrawerItemActions
              count={count}
              name={name}
              handleEditQuantity={editProductQuantity}
              handleRemoveProduct={removeProduct}
              maxQuantity={maxCartQuantity}
            />
          </Flex>
        </Flex>
      </Card.Content>
    </CartDrawerItem>
  );
};
