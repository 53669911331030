import createCache from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import { useState } from 'react';
import root from 'react-shadow';

import './shadow-styles.css';

const ShadowRootDiv = root['div'];

const ShadowRoot = ({ children }) => {
  const [emotionCache, setEmotionCache] = useState(null);

  function setEmotionStyles(ref) {
    if (ref && !emotionCache) {
      const createdEmotionWithRef = createCache({
        key: 'css',
        container: ref,
      });
      setEmotionCache(createdEmotionWithRef);
    }

    // In production, we want to move the css from the head to here (since we can't figure out a better way to do it)
    const linkTag = document.querySelector('link[href*=framelessEmbed]');
    if (linkTag && ref) {
      ref.appendChild(linkTag);
    }
  }

  return (
    <ShadowRootDiv id="shadow-host">
      <div ref={setEmotionStyles} id="shadow-emotion">
        {emotionCache && (
          <EmotionCacheProvider value={emotionCache}>
            {/*
            Modals and popovers go into this element. For accessibility reasons
            the modals should not "portal" into the app itself but into
            a sibling according to react-modal:
            https://reactcommunity.org/react-modal/#using-a-custom-parent-node
            */}
            <div id="shadow-app-sibling"></div>
            <div id="shadow-app">{children}</div>
          </EmotionCacheProvider>
        )}
      </div>
    </ShadowRootDiv>
  );
};

export default ShadowRoot;
