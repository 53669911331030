import indexOf from 'lodash/indexOf';
import sortBy from 'lodash/sortBy';

import type { SearchResponse } from '@jane/search/types';
import type { Store } from '@jane/shared/models';
import type { Store as zStore } from '@jane/shared/types';

import { formatFilterLabel } from './formatFilterLabel';
import { JANE_DEFINED_ROWS } from './searchStateUtils';

export interface FeaturedCategoryCarousel {
  count: number;
  isJaneDefined: boolean;
  rootType: string;
  title: string;
}

export const sortFeaturedCategories = (
  customRows: FeaturedCategoryCarousel[],
  customRanking: Store['custom_product_type_ranking'],
  storeId: Store['id']
) =>
  customRanking && customRows
    ? sortBy(customRows, ({ rootType, title }) =>
        indexOf(customRanking, rootType.replace(`-${storeId}`, '') || title)
      )
    : customRows;

export function buildFeaturedCategoryCarousels(
  facets: SearchResponse['facets'],
  {
    custom_rows,
    custom_product_type_labels,
    custom_product_type_ranking,
    id,
  }: Store | zStore
): FeaturedCategoryCarousel[] | null {
  if (!facets) return null;

  const rootTypes = facets['root_types'];

  const allRows = custom_rows ? [...custom_rows, 'specials'] : ['specials'];

  const customRows = allRows.filter(Boolean).map((row) => {
    const isJaneDefined = JANE_DEFINED_ROWS.includes(row);
    const rootType = isJaneDefined ? row : `${row}-${id}`;

    return {
      count: (rootTypes && rootTypes[rootType]) || 0,
      rootType,
      isJaneDefined,
      title: formatFilterLabel(row, custom_product_type_labels, id),
    };
  });

  const sortedCarousels = sortFeaturedCategories(
    customRows,
    custom_product_type_ranking,
    id
  );

  return sortedCarousels;
}
