import clsx from 'clsx';
import type { CSSProperties } from 'react';

import { getAccessibilityProps, toCSSSize } from '../../../internal/utils';
import type { AccessibilityProps } from '../../../internal/utils';
import type { ColorKey } from '../../../theme';
import {
  fakeButtonProps,
  getColorCSSVar,
  getColorForBackgroundCSSVar,
  handleEnterKey,
} from '../../../utils';
import type { ReeferBaseProps } from '../../../utils/baseProps.types';
import type { CardBaseProps } from '../card';
import styles from './cardContent.module.css';

export interface CardContentProps
  extends AccessibilityProps,
    CardBaseProps,
    ReeferBaseProps {
  /** Add a background color to the component */
  background?: ColorKey;
}

/**
 * In general, the `Card.Content` component should always be included as a child of a `Card` component.
 * The main contents of the card are added as children of this component.
 * */

export function CardContent({
  ariaLabel,
  ariaLabelledBy,
  background = 'grays-white',
  border = 'none',
  borderWidth = '1px',
  children,
  className,
  id,
  onClick,
  role,
  'data-testid': testId,
  height = '100%',
  width = '100%',
}: CardContentProps) {
  const a11yProps = getAccessibilityProps({
    ariaLabel,
    ariaLabelledBy,
    role,
    onClick,
  });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, styles.cardContent, {
        [styles.cardContent__clickable]: !!onClick,
      })}
      id={id}
      style={
        {
          '--cardContent-background-color': getColorCSSVar(background),
          '--cardContent-border':
            border === 'none' ? 'none' : `solid ${getColorCSSVar(border)}`,
          '--cardContent-border-width': border === 'none' ? 0 : borderWidth,
          '--cardContent-color': getColorForBackgroundCSSVar(background),
          '--cardContent-height': toCSSSize(height),
          '--cardContent-width': toCSSSize(width),
        } as CSSProperties
      }
      data-testid={testId}
      onClick={onClick}
      onKeyUp={(event) => handleEnterKey(event, onClick)}
      {...(onClick && fakeButtonProps)}
      {...a11yProps}
    >
      {children}
    </div>
  );
}
