import { placementPDPRow, placementRecommendedRow } from '@iheartjane/dm-sdk';

import { ZoneStoreMenu, ZoneStorePdp } from '@jane/shared/models';
import type {
  AppMode,
  DeepReadonly,
  Store,
  StoreDmSetting,
  Zone,
} from '@jane/shared/models';
import { getSurfaceByAppMode } from '@jane/shared/util';

const validZones = [ZoneStoreMenu, ZoneStorePdp];

export const useIsMagicRowEnabled = ({
  appMode,
  store,
  zone,
}: {
  appMode: AppMode;
  store: Store | undefined;
  zone: Zone;
}): boolean => {
  // TODOBOOSE we may be able to remove the store_dm_settings check. api will check that on its own. leaving for now.
  return isMagicRowEnabled({
    appMode,
    storeDmSettings: store?.store_dm_settings,
    zone,
  });
};

interface IsMagicRowEnabledProps {
  appMode: AppMode;
  storeDmSettings: DeepReadonly<StoreDmSetting[]> | null | undefined;
  zone: Zone;
}

export const isMagicRowEnabled = ({
  appMode,
  storeDmSettings,
  zone,
}: IsMagicRowEnabledProps): boolean => {
  if (!storeDmSettings) {
    return false;
  }
  if (!validZones.includes(zone)) {
    return false;
  }

  // initially, PDP will use placement='pdp_row' and storeMenu will use placement='magic_row'
  // eventually both will switch to placement='magic_row'
  const placementToCheck =
    zone === ZoneStoreMenu ? placementRecommendedRow : placementPDPRow;

  return storeDmSettings.some((setting) => {
    const isEnabled = setting.enabled;
    const isForThisSurface = getSurfaceByAppMode(appMode) === setting.surface;
    const isForMagicRow = setting.placement === placementToCheck;
    return isEnabled && isForThisSurface && isForMagicRow;
  });
};
