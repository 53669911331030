import styled from '@emotion/styled';

import { Card } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

interface JaneGoldBannerStyle {
  fullWidth: boolean;
}

export const BannerContainer = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
  bottom: '0px',
  // 1 lower than the header z-index, so that header overlay items can be visible
  zIndex: 'calc(var(--layers-header) - 1)',
  [mediaQueries.tablet('min')]: {
    position: 'static',
  },
});

export const StyledJaneGoldBanner = styled(Card.Group)<JaneGoldBannerStyle>(
  ({ fullWidth, theme }) => ({
    backgroundColor: theme.colors.grays.white,
    boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: fullWidth ? '0px' : theme.borderRadius.lg,
    span: {
      cursor: 'inherit',
    },
    [mediaQueries.tablet('min')]: {
      boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.10)',
      ...(fullWidth && {
        ...spacing({ px: 48 }),
      }),
    },
  })
);
