import { css } from '@emotion/react';
import type { CSSObject } from '@emotion/react';

import ChevronFatIcon from './icons/chevron-fat.svg?react';
import CircleCheckFilled from './icons/circleCheckFilled.svg?react';
import Dot from './icons/dot.svg?react';
import StarHalf from './icons/star-half.svg?react';
import StarFilled from './icons/starFilled.svg?react';
import UserCircle from './icons/user-circle.svg?react';

interface Icons {
  [key: string]: any;
}

const icons: Icons = {
  chevronFat: ChevronFatIcon,
  circleCheckFilled: CircleCheckFilled,
  dot: Dot,
  starFilled: StarFilled,
  starHalf: StarHalf,
  userCircle: UserCircle,
};

type Size = number | { height?: number; width?: number };

interface Props {
  ariaHidden?: boolean;
  ariaLabel?: string;
  color?: string;
  dim?: boolean;
  display?: string;
  hidden?: boolean;
  icon: keyof Icons;
  role?: string;
  scale?: number;
  size?: Size;
  styles?: CSSObject;
}

const transform = (scale: Props['scale']) => {
  const transformValues = [];

  if (scale) transformValues.push(`scale(${scale})`);

  if (transformValues.length === 0) return undefined;
  return transformValues.join(' ');
};

export default function SvgIcon({
  color,
  dim = !color,
  icon,
  size = 18,
  hidden,
  display,
  ariaHidden,
  ariaLabel,
  role,
  scale,
  styles,
}: Props) {
  const Icon = icons[icon];
  if (!Icon) {
    return null;
  }

  return (
    <Icon
      css={css(
        typeof size === 'number' ? { height: size, width: size } : size,
        {
          fill: color || 'currentColor',
          transform: transform(scale),
          visibility: hidden ? 'hidden' : undefined,
          opacity: dim ? 0.9 : 1.0,
          display,
          ...styles,
        }
      )}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      role={role}
    />
  );
}
