import uniq from 'lodash/uniq';

import type {
  AddedOrUpdatedProductInCart,
  UserPreferencesType,
} from '@jane/shared-ecomm/tracking';
import {
  EventNames,
  coreProductProperties,
  track,
} from '@jane/shared-ecomm/tracking';
import { config } from '@jane/shared/config';
import type {
  Cart,
  Id,
  MenuProduct,
  PriceId,
  Store,
  StoreSpecial,
} from '@jane/shared/models';
import type { AlmostGone, UserSegmentIds } from '@jane/shared/types';
import {
  currencyCodeFromCountryCode,
  getActualUnitPrice,
  getUnitPrice,
} from '@jane/shared/util';

import { isNoStore } from '../../lib/customer/redux/store';

const buildFiltersFromMenuProduct = (menuProduct: MenuProduct) => {
  const filters = [];
  const algoliaFilterMap = {
    brand: menuProduct.brand,
    kind: menuProduct.kind,
    category: menuProduct.category,
    brand_subtype: menuProduct.brand_subtype,
    root_subtype: menuProduct.root_subtype,
  };

  for (const [key, value] of Object.entries(algoliaFilterMap)) {
    if (value) {
      filters.push(`${key}:${value}`);
    }
  }

  return filters;
};

interface TrackCartEventProps
  extends Pick<
    AddedOrUpdatedProductInCart,
    'event' | 'columnPosition' | 'location' | 'productLocation' | 'rowPosition'
  > {
  almostGone?: AlmostGone;
  cartId?: Id;
  count: number;
  menuProduct: MenuProduct;
  price_id: PriceId;
  source: string;
  special?: StoreSpecial;
  store: Store;
  userPreferences: UserPreferencesType;
  userSegments?: UserSegmentIds;
}

export const trackCartEvent = ({
  almostGone,
  cartId,
  count,
  event,
  menuProduct,
  price_id,
  store,
  special,
  location,
  source,
  userPreferences,
  userSegments,
  ...props
}: TrackCartEventProps) => {
  const pricePerUnit =
    getActualUnitPrice(menuProduct as MenuProduct, price_id, special) || 0;
  const pricePerUnitWithoutDiscount =
    getUnitPrice(menuProduct as MenuProduct, price_id) || 0;

  const priceTotal = pricePerUnit * count;

  const storeCurrency = !isNoStore(store)
    ? currencyCodeFromCountryCode(store.country_code || '')
    : undefined;

  track({
    almostGone,
    cartId,
    event,
    location,
    indexName: `products-${config.algoliaEnv}`,
    objectIds: [menuProduct.id.toString()],
    filters: buildFiltersFromMenuProduct(menuProduct),
    storeId: store.id.toString(),
    storeName: store.name,
    storeCity: store.city || undefined,
    storeCurrency,
    storeState: store.state || undefined,
    ...coreProductProperties(menuProduct, userSegments),
    quantity: count,
    priceUnit: price_id,
    pricePerUnit,
    priceTotal,
    recommended: !!menuProduct.recommended,
    ...(special
      ? {
          specialId: (menuProduct as any).special_id?.toString() || '',
          specialDiscountPerUnit: pricePerUnitWithoutDiscount - pricePerUnit,
          specialDiscountTotal:
            (pricePerUnitWithoutDiscount - pricePerUnit) * count,
        }
      : {}),
    source,
    ...userPreferences,
    ...props,
  });
};

export const trackCheckoutSuccess = ({
  cart,
  store,
  paymentMethod,
}: {
  cart: Cart;
  paymentMethod?: string;
  store?: any;
}) => {
  const { id: cartId, products } = cart;

  const objectIds = products.map((product) => product.id.toString());

  const filters = products.reduce<string[]>((acc, product) => {
    acc.push(...buildFiltersFromMenuProduct(product));
    return acc;
  }, []);

  const priceTotal = products.reduce<number>((acc, product) => {
    const unitPrice =
      getActualUnitPrice(product as MenuProduct, product.price_id) || 0;
    acc += product.count * unitPrice;
    return acc;
  }, 0);

  const storeCurrency = store
    ? currencyCodeFromCountryCode(store.country_code)
    : undefined;

  const eventsProducts = products.reduce<any>((acc, product) => {
    acc.push({
      productId: product.id,
      productName: product.name,
      productKind: product.kind,
      productBrand: product.brand,
      quantity: product.count,
      priceUnit:
        getActualUnitPrice(product as MenuProduct, product.price_id) || 0,
    });
    return acc;
  }, []);

  let trackableCartId;
  if (cartId) {
    trackableCartId = cartId;
  } else {
    trackableCartId = `cart-${Date.now().toString()}`;
  }

  const paymentOptions = uniq([
    ...(store?.prepayment_providers || []),
    ...(store?.pickup_payment_options || []),
    ...(store?.delivery_payment_options || []),
    ...(store?.curbside_payment_options || []),
  ]);

  track({
    event: EventNames.CheckoutSuccess,
    indexName: `products-${config.algoliaEnv}`,
    filters: uniq(filters),
    products: eventsProducts,
    objectIds,
    priceTotal,
    paymentMethod,
    paymentOptions,
    cartId: trackableCartId,
    storeCurrency,
  });
};
