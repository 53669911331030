import type { SmartSortProduct } from '@iheartjane/dm-sdk';

import { trackImpressedInlineProduct } from '@jane/shared-ecomm/tracking';
import type {
  AdData,
  AdFlight,
  AppMode,
  Id,
  MenuProduct,
  Zone,
} from '@jane/shared/models';
import { ZoneCart, ZoneStoreMenuInline } from '@jane/shared/models';

interface TrackNonSdkImpressionProps {
  appMode: AppMode;
  flight?: AdFlight;
  janeDeviceId: string;
  menuProduct: MenuProduct;
  menuRowName: string;
  storeId: Id;
  userId?: number;
  zone: Zone;
}
const trackNonSdkImpression = ({
  appMode,
  menuRowName,
  flight,
  janeDeviceId,
  menuProduct,
  storeId,
  userId,
  zone,
}: TrackNonSdkImpressionProps) => {
  const placementRow = menuRowName;
  if (!menuProduct.dmMeta?.adToken) {
    return;
  }
  trackImpressedInlineProduct({
    adToken: menuProduct.dmMeta.adToken,
    appMode,
    flight,
    janeDeviceId,
    myHighD: menuProduct.dmMeta.myHighD,
    placementRow,
    product: menuProduct,
    storeId,
    userId,
    zone,
  });
};

interface TrackImpressionProps {
  appMode: AppMode;
  cardIndex?: number;
  columnPosition?: number | null;
  flightProps?: AdData['flight'];
  isInView: boolean;
  janeDeviceId: string;
  janeGoldLabel?: string | null;
  menuProduct: MenuProduct;
  menuRowName: string;
  numColumns?: number;
  productInstance?: SmartSortProduct;
  rowPosition?: number | null;
  storeId: Id;
  userId?: number;
  zone: Zone;
}

export const trackImpression = async ({
  appMode,
  cardIndex,
  columnPosition,
  flightProps,
  isInView,
  janeDeviceId,
  janeGoldLabel,
  menuRowName,
  menuProduct,
  numColumns,
  productInstance,
  rowPosition,
  storeId,
  userId,
  zone,
}: TrackImpressionProps) => {
  if (!isInView) return;

  const shouldTrackDmSdkImpression = productInstance !== undefined;
  if (shouldTrackDmSdkImpression) {
    productInstance.impress();
  }

  const isCorrectZone = [ZoneStoreMenuInline, ZoneCart].includes(zone);
  const shouldTrackNonSdkImpression =
    !shouldTrackDmSdkImpression && isCorrectZone;
  if (shouldTrackNonSdkImpression) {
    trackNonSdkImpression({
      appMode,
      menuRowName,
      flight: flightProps,
      janeDeviceId,
      menuProduct,
      storeId,
      userId,
      zone,
    });
  }
};
