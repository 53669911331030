import { useCallback, useEffect, useRef, useState } from 'react';

interface UseMenuTopRowTimerResult {
  isTimerFinished: boolean;
  pauseTimer: () => void;
  restartTimer: () => void;
  timeLeft: number;
  unpauseTimer: () => void;
}

export const useMenuTopRowTimer = (
  timeInSeconds: number
): UseMenuTopRowTimerResult => {
  const [timeLeft, setTimeLeft] = useState(timeInSeconds);
  const [isPaused, setIsPaused] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const pauseTimer = useCallback(() => {
    setIsPaused(true);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, []);

  const unpauseTimer = useCallback(() => {
    setIsPaused(false);
  }, []);

  const restartTimer = useCallback(() => {
    setTimeLeft(timeInSeconds);
  }, [timeInSeconds, timeLeft]);

  useEffect(() => {
    if (!timeLeft) return;

    if (!isPaused) {
      timerRef.current = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [timeLeft, isPaused]);

  return {
    isTimerFinished: timeLeft <= 0,
    pauseTimer,
    restartTimer,
    timeLeft,
    unpauseTimer,
  };
};
