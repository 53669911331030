import {
  placementMenuInline,
  placementMenuInlineTable,
} from '@iheartjane/dm-sdk';

import type {
  AppMode,
  DeepReadonly,
  Store,
  StoreDmSetting,
} from '@jane/shared/models';
import { getSurfaceByAppMode } from '@jane/shared/util';

import {
  RecommendedSortVersion,
  useGetRecommendedSortVersion,
} from './useGetRecommendedSortVersion';

export const useIsRecommendedSortEnabled = (
  store: Store | undefined,
  appMode: AppMode
): boolean => {
  const flagEnabled =
    RecommendedSortVersion.off !== useGetRecommendedSortVersion();

  return isRecommendedSortEnabled({
    appMode,
    flagEnabled,
    storeDMSettings: store?.store_dm_settings,
  });
};

export const isRecommendedSortEnabled = ({
  appMode,
  flagEnabled,
  storeDMSettings,
}: {
  appMode: AppMode;
  flagEnabled: boolean;
  storeDMSettings: DeepReadonly<StoreDmSetting[]> | null | undefined;
}): boolean => {
  return Boolean(
    flagEnabled &&
      storeDMSettings &&
      storeDMSettings.some((setting) => {
        const isEnabled = setting.enabled;
        const isForThisSurface =
          getSurfaceByAppMode(appMode) === setting.surface;
        const isForInlineAds = [
          placementMenuInline,
          placementMenuInlineTable,
        ].includes(setting.placement);
        return isEnabled && isForThisSurface && isForInlineAds;
      })
  );
};
