import styled from '@emotion/styled';
import omit from 'lodash/omit';
import { useCallback, useMemo } from 'react';
import { InView } from 'react-intersection-observer';
import { useLocation, useParams } from 'react-router-dom';

import { useDeepCompareMemo } from '@jane/shared-ecomm/hooks';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import {
  track,
  trackClickedAd,
  trackProductListingTap,
} from '@jane/shared-ecomm/tracking';
import { LegacyMenuProductCard } from '@jane/shared/components';
import { useJaneUserId, useUserSegmentIds } from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { useShouldShowGold } from '@jane/shared/hooks';
import type {
  MenuProduct,
  PriceId,
  StoreSpecial as ReduxStoreSpecial,
  StoreSpecial,
} from '@jane/shared/models';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import type { MenuProduct as ZMenuProduct } from '@jane/shared/types';
import {
  deserializeCannabinoids,
  getJaneGoldTagLabel,
  getMaxCartQuantity,
  monolithPaths,
  shouldProductShowSpecial,
} from '@jane/shared/util';

import { buildClickTrackingEvent } from './buildTrackingEvent';
import { findSpecialForProduct } from './findSpecialForProduct';
import type { ProductCardWithTrackingProps } from './productCardWithTracking.types';
import { trackImpression } from './trackImpression';

const InViewFlex = styled(InView)({
  display: 'flex',
  flexDirection: 'column',
});

export const ProductCardWithTracking = ({
  algoliaIndexName,
  attemptToAddToCart,
  cardIndex,
  closeCart,
  columnPosition,
  currentCycleIndex,
  deleteCartItem,
  janeDeviceId,
  menuRowName,
  numColumns,
  product,
  productLocation,
  productInstance,
  rowPosition,
  specials,
  withoutDrawerOnAddToCart,
  zone,
  ...props
}: ProductCardWithTrackingProps) => {
  const { appMode, store, carouselView, listView } = props;
  const { isPartnerHosted, partnerHostedPath } = useRuntimeConfig();
  const { userLocation, userPreferences = {} } = useUserPreferences();
  const shouldShowGold = useShouldShowGold({ product, store });
  const { special_id } = useParams();
  const { storeFulfillmentType, storeSearchRadius, storeType } =
    userPreferences;
  const { pathname } = useLocation();
  const userId = useJaneUserId();
  const janeGoldSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);
  const userSegments = useUserSegmentIds();

  const {
    city: storeCity,
    state: storeState,
    name: storeName,
    id: storeId,
  } = store;

  const menuProduct = useDeepCompareMemo<any>(() => {
    const mp = {
      ...deserializeCannabinoids(product, 'menuProduct'),
      id: product['product_id'],
    };
    return omit(mp, 'product_id') as MenuProduct;
  }, [product]);

  const janeGoldLabel = shouldShowGold
    ? getJaneGoldTagLabel({
        menuProduct: menuProduct,
      })
    : undefined;

  const showProductSpecial = shouldProductShowSpecial({
    product: menuProduct,
    userSegments: janeGoldSegmentation ? userSegments : undefined,
  });

  const pageSpecial = specials?.find(
    (special) => special.id === Number(special_id)
  );

  const currentSpecial =
    pageSpecial?.special_type === 'product'
      ? pageSpecial
      : findSpecialForProduct(menuProduct, specials as StoreSpecial[], appMode);

  const flightProps = menuProduct.flight;

  const clickTrackingEvent = buildClickTrackingEvent({
    indexName: algoliaIndexName,
    flightId: flightProps?.id,
    creativeIds: flightProps?.creative_ids,
    store,
    menuProduct,
    carouselView,
    rowPosition,
    cardIndex,
    columnPosition,
    listView,
    menuRowName,
    currentCycleIndex,
    userSegments: janeGoldSegmentation ? userSegments : undefined,
  });

  const handleTrackImpression = useCallback(
    async (isInView: boolean) =>
      trackImpression({
        appMode,
        menuRowName,
        columnPosition,
        flightProps,
        janeGoldLabel: showProductSpecial ? janeGoldLabel : undefined,
        cardIndex,
        isInView,
        janeDeviceId: janeDeviceId || '',
        menuProduct,
        numColumns: numColumns || undefined,
        productInstance,
        storeId,
        userId,
        zone,
      }),
    [
      appMode,
      menuRowName,
      columnPosition,
      flightProps,
      showProductSpecial,
      janeGoldLabel,
      cardIndex,
      janeDeviceId,
      menuProduct,
      numColumns,
      productInstance,
      storeId,
      userId,
      zone,
    ]
  );

  const handleMenuProductCardClick = useMemo(() => {
    return (selectedWeight: PriceId | null) => {
      if (productInstance) {
        productInstance.click();
      }

      if (!productInstance) {
        trackClickedAd({
          flight: flightProps,
          dmMeta: menuProduct.dmMeta,
          janeDeviceId: janeDeviceId || '',
          productId: menuProduct.id,
          menuProductBrandId: menuProduct.product_brand_id,
        });
      }

      clickTrackingEvent && track(clickTrackingEvent);

      const inventoryCount =
        !!menuProduct && !!selectedWeight
          ? getMaxCartQuantity(menuProduct, selectedWeight)
          : null;

      trackProductListingTap({
        almostGone: inventoryCount < 4 ? inventoryCount : null,
        cityState: userLocation?.cityState,
        columnPosition,
        product: menuProduct,
        productLocation,
        rowPosition,
        source: pathname,
        storeId: String(storeId),
        storeName,
        storeCity: storeCity || undefined,
        storeState: storeState || undefined,
        storeFulfillmentType,
        storeSearchRadius,
        storeType,
        userSegments: janeGoldSegmentation ? userSegments : undefined,
        zipcode: userLocation?.zipcode,
      });
    };
  }, [
    clickTrackingEvent,
    columnPosition,
    flightProps,
    janeDeviceId,
    janeGoldSegmentation,
    menuProduct,
    pathname,
    productInstance,
    productLocation,
    rowPosition,
    storeCity,
    storeFulfillmentType,
    storeId,
    storeName,
    storeSearchRadius,
    storeState,
    storeType,
    userLocation?.cityState,
    userLocation?.zipcode,
    userSegments,
  ]);

  return (
    <InViewFlex
      data-testid="menu-product-card-with-tracking"
      delay={1000}
      onChange={(isInView) => handleTrackImpression(isInView)}
      threshold={0.5}
      triggerOnce
    >
      <LegacyMenuProductCard
        currentSpecial={currentSpecial as ReduxStoreSpecial}
        product={menuProduct}
        productInstance={productInstance}
        onAddToCart={(addData) => {
          attemptToAddToCart({
            ...addData,
            columnPosition,
            menuProduct: addData.menuProduct as ZMenuProduct,
            productLocation,
            rowPosition,
            withoutDrawer: withoutDrawerOnAddToCart,
          });
        }}
        onDeleteFromCart={(deleteData) => {
          deleteCartItem(deleteData.itemId, deleteData.selectedWeight);
        }}
        onCloseCart={() => closeCart()}
        onClick={handleMenuProductCardClick}
        routeAppMode={monolithPaths.getAppMode()}
        routePartnerHostedConfig={{ isPartnerHosted, partnerHostedPath }}
        userLocation={userLocation}
        janeGoldLabel={janeGoldLabel}
        {...props}
      />
    </InViewFlex>
  );
};
