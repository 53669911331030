import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';
import * as t from 'io-ts';
import qs from 'qs';

import { config } from '@jane/shared/config';
import type {
  Address,
  AppMode,
  Id,
  Location,
  PaymentMethod,
  Preferences,
  PriceId,
  ReservationMode,
  Tags,
  UserConsentItem,
} from '@jane/shared/models';
import { Storage } from '@jane/shared/util';

import { cartStorageKey } from '../customer/redux/cart';
import { decodeType } from '../lib/loadable';
import { request } from '../lib/request';

const buildUrl = (url = '') => `${config.apiPath}/carts${url}`;

interface CreateParamsProduct {
  count: number;
  price_id: PriceId;
  product_id: Id;
}
interface CreateParams {
  app_mode?: AppMode;
  app_mode_name?: AppMode;
  external_source?: string;
  headless_checkout_partner_id?: string;
  products: CreateParamsProduct[];
  promo_code?: string;
  reservation_mode: ReservationMode;
  store_id: Id;
  tags?: Tags;
  user_agent: string;
  user_group_special_id?: Id;
}

interface UpdateParams {
  aeropay_preauthorization_id?: string;
  anonymous_user_id?: string;
  canpay_intent_id?: string;
  canpay_token?: string;
  currency_code?: string;
  current_url?: string;
  delivery_address?: Address | null;
  delivery_fee_amount?: number;
  delivery_finished_at?: string;
  delivery_started_at?: string;
  message?: string;
  moneris_ticket?: string | null;
  payfirma_card_cvv2?: string;
  payfirma_card_expiry_month?: string;
  payfirma_card_expiry_year?: string;
  payfirma_card_number?: string;
  payfirma_card_postal_code?: string;
  payment_method?: PaymentMethod;
  recaptcha_token?: string | null;
  reservation_mode?: ReservationMode;
  tags?: Tags;
  user_group_special_id?: Id;
  user_id?: Id | null;
  uuid: string;
}

interface CanPayApprovedIntentData {
  intent_id: string;
  response: string;
  signature: string;
}

export interface CheckoutRequest {
  aeropay_account_verified: boolean;
  aeropay_bank_account_id?: string;
  aeropay_preauthorization_id?: string;
  age_verification: {
    birth_date?: string;
    government_identification_url?: string | null;
  };
  anonymous_user_id?: string;
  canpay_token?: string;
  canpay_v2remotepay_transaction?: CanPayApprovedIntentData;
  current_url: string;
  customer: {
    email?: string;
    external_user_id?: string | null;
    name: string;
    phone?: string;
  };
  delivery_address?: Address;
  delivery_finished_at?: string;
  delivery_started_at?: string;
  headless_checkout_partner_id?: string;
  is_curbside_pickup: boolean;
  j_device_id?: string;
  medical_identification: {
    id_back_url?: string | null;
    id_front_url?: string | null;
    id_number?: string | null;
  };
  message: string;
  moneris_ticket?: string | null;
  payfirma_card_cvv2?: string;
  payfirma_card_expiry_month?: string;
  payfirma_card_expiry_year?: string;
  payfirma_card_number?: string;
  payfirma_card_postal_code?: string;
  payment_method: PaymentMethod;
  promotions_opt_out?: boolean;
  recaptcha_token?: string | null;
  reservation_mode: ReservationMode;
  square_payment_token?: string;
  stronghold_payment_source_id?: string;
  tags?: Tags;
  tip_amount: number;
  user_consent: UserConsentItem[];
  user_group_special_id?: Id;
  user_location: Location;
  user_preferences: Preferences;
  uuid: string;
}

const tCheckoutResponse = t.interface({
  message: t.string,
  warning_message: t.union([t.string, t.undefined, t.null]),
  reservation: t.interface({
    uuid: t.string,
    id: t.number,
    amount: t.union([t.string, t.number]),
    delivery_started_at: t.union([t.number, t.null]),
    delivery_finished_at: t.union([t.number, t.null]),
    payment_method: t.union([t.string, t.null]),
    message: t.union([t.string, t.null]),
  }),
  contact: t.union([
    t.null,
    t.undefined,
    t.interface({
      is_registered: t.boolean,
    }),
  ]),
});

export type CheckoutResponse = t.TypeOf<typeof tCheckoutResponse>;

export const CartSource = {
  create(body: CreateParams) {
    return request(buildUrl(), {
      method: 'POST',
      body: JSON.stringify(body),
    });
  },
  optimizeSpecials({
    user_group_special_id,
    reservation_mode,
    promo_code,
    uuid,
    delivery_address,
  }: {
    delivery_address?: Address | null;
    promo_code: string | undefined;
    reservation_mode: ReservationMode;
    user_group_special_id: Id | undefined;
    uuid: string;
  }) {
    return request(buildUrl(`/optimize_specials`), {
      body: JSON.stringify({
        user_group_special_id,
        reservation_mode,
        promo_code,
        uuid,
        delivery_address,
      }),
      method: 'PATCH',
    });
  },

  getRemote(uuid: string, delivery_address?: Address | null) {
    const queryParams = qs.stringify({
      uuid,
      delivery_address: delivery_address ? delivery_address : undefined,
    });
    return request(buildUrl(`?${queryParams}`), {
      method: 'GET',
    });
  },

  getLocal(appMode: AppMode, storeId: Id | undefined) {
    const storeAppCart = Storage.get(cartStorageKey(appMode, storeId));

    if (storeAppCart) {
      return Promise.resolve({ cart: storeAppCart });
    }

    return Promise.resolve({ cart: {} });
  },

  getForUser(app_mode: AppMode, store_id: Id | undefined, uuid: string) {
    const queryParams = qs.stringify({ uuid, app_mode, store_id });
    return request(buildUrl(`/get_for_user?${queryParams}`), {
      method: 'GET',
    });
  },

  getUnavailableProductIds(uuid: string) {
    return request(
      buildUrl(`/get_unavailable_product_ids?${qs.stringify({ uuid })}`),
      {
        method: 'GET',
      }
    );
  },

  delete(uuid: string) {
    return request(buildUrl(), {
      method: 'DELETE',
      body: JSON.stringify({ uuid }),
    });
  },

  update(cart: UpdateParams) {
    return request(buildUrl(), {
      body: JSON.stringify(cart),
      method: 'PATCH',
    });
  },

  save(checkout: CheckoutRequest) {
    return request(buildUrl('/checkout'), {
      body: JSON.stringify(checkout),
      method: 'POST',
    })
      .then((r) =>
        decodeType({
          data: r,
          type: tCheckoutResponse,
          source: { name: 'CartSource.save' },
        })
      )
      .then((r) =>
        pipe(
          r,
          fold(
            (error) => {
              throw error;
            },
            (response) => response
          )
        )
      );
  },

  removeSpecialHasChangedFlag(uuid: string) {
    return request(buildUrl('/changed_special_flag'), {
      method: 'DELETE',
      body: JSON.stringify({ uuid }),
    });
  },

  lookupSalesTaxRate(
    address: Address,
    reservation_mode: ReservationMode,
    uuid: string
  ) {
    const { street, city, state_code, zipcode } = address;
    const queryParams = qs.stringify({
      street,
      city,
      state_code,
      zipcode,
      reservation_mode,
      uuid,
    });
    return request(buildUrl(`/lookup_sales_tax_rate?${queryParams}`), {
      method: 'GET',
    });
  },

  getBundlePossibilities(uuid: string, touched_product_id: Id) {
    const queryParams = qs.stringify({
      uuid,
      touched_product_id,
    });
    return request(buildUrl(`/find_bundle_specials?${queryParams}`), {
      method: 'GET',
    });
  },
};
