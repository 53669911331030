import { useEffect, useRef } from 'react';

const inIframe = () => {
  if (typeof window !== 'undefined') {
    return window.self !== window.top;
  } else {
    return false;
  }
};

interface PostMessageToIframParentArgs {
  messageType: string;
  payload?: any;
  setOptions?: {
    preventContainerResizing?: boolean;
    preventResizing?: boolean;
  };
}

const postMessageToIframeParent = ({
  messageType,
  payload,
  setOptions,
}: PostMessageToIframParentArgs) => {
  const pathname = window.location.href;
  window.parent.postMessage(
    { messageType, pathname, payload, setOptions },
    '*'
  );
};

export const useHandleMobilePopover = (
  isOpen: boolean,
  isMobile: boolean,
  isLayered: boolean,
  scrollPosition?: boolean
) => {
  const hasOpened = useRef(false);
  const isInIframe = inIframe();

  const onCloseMobilePopover = () => {
    if (!isLayered) {
      document.body.style.overflow = 'unset';
    }

    if (isInIframe) {
      postMessageToIframeParent({
        messageType: 'disableShrinkEveryResize',
      });

      postMessageToIframeParent({
        messageType: 'closeModal',
      });

      if (scrollPosition) {
        postMessageToIframeParent({
          messageType: 'restoreScrollPosition',
          payload: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    if (isMobile) {
      if (isOpen) {
        hasOpened.current = true;
        document.body.style.overflow = 'hidden';

        if (isInIframe) {
          if (scrollPosition) {
            postMessageToIframeParent({
              messageType: 'saveScrollPosition',
            });
          }

          postMessageToIframeParent({
            messageType: 'enableShrinkEveryResize',
          });

          postMessageToIframeParent({
            messageType: 'openModal',
          });

          postMessageToIframeParent({
            messageType: 'scrollToTop',
          });
        }
      }

      if (!isOpen && hasOpened?.current === true) {
        onCloseMobilePopover();
      }
    }

    return () => {
      if (isOpen) {
        onCloseMobilePopover();
      }
    };
  }, [isOpen, isMobile, isInIframe, scrollPosition]);
};
