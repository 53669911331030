import { useCallback, useState } from 'react';

import { useJaneGoldData } from '@jane/shared-ecomm/hooks';
import { EventNames, linkBankAccountEvent } from '@jane/shared-ecomm/tracking';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Box } from '@jane/shared/reefer';

import { BankLinkingModal } from '../bankLinkingModal';
import { LogInModal } from '../logInModal';
import type { JaneGoldCalloutProps } from './janeGoldCallout.types';
import { JaneGoldCalloutDescription } from './janeGoldCalloutDescription';
import { JaneGoldCartCallout } from './janeGoldCartCallout';
import { JaneGoldCheckoutCallout } from './janeGoldCheckoutCallout';
import { JaneGoldCheckoutSummaryCallout } from './janeGoldCheckoutSummaryCallout';
import { JaneGoldPdpCallout } from './janeGoldPdpCallout';

/**
 * A component used for representing Jane Gold callout action. This callout has three different variants,
 * depending on the context where it is used. Different variants render different content and calls to action, depending on if the user has a linked bank or not.
 */
export function JaneGoldCallout({
  authentication,
  defaultOpen = false,
  discountLabel,
  feeLabel,
  headlessLoginUrl,
  onLinkAccountClick,
  onMenu,
  segmentedDeal = false,
  totalLabel,
  variant = 'pdp',
  ...props
}: JaneGoldCalloutProps) {
  const { isGuestUser, userData, userHasLinkedBank } = useJaneGoldData();
  const [openModal, setOpenModal] = useState(defaultOpen);
  const janeGoldServiceFee = useFlag(FLAGS.janeGoldServiceFee);

  const pdpCalloutTitle = `${discountLabel}`;
  const cartCalloutTitle = 'Earn cash back with Jane Gold';
  const checkoutCalloutTitle = 'Earn Jane Gold cash back';

  const CalloutDescription = () => {
    return (
      <JaneGoldCalloutDescription
        isGuestUser={isGuestUser}
        userHasLinkedBank={userHasLinkedBank}
        variant={variant}
      />
    );
  };

  const buttonLabel =
    isGuestUser && variant !== 'checkout'
      ? `Sign in${segmentedDeal && variant === 'pdp' ? ' for eligibility' : ''}`
      : 'Link bank account';

  const hasFeeBreakdown =
    janeGoldServiceFee && Boolean(feeLabel && Number(feeLabel) > 0);

  const handleOnLinkAccountClick = useCallback(() => {
    linkBankAccountEvent({
      email: userData?.user.email || '',
      event: EventNames.LinkBankAccountTap,
      source: variant,
      username: userData?.user.nickname || '',
    });
    onLinkAccountClick && onLinkAccountClick();

    setOpenModal(true);
  }, [userData, variant]);

  return (
    <Box {...props}>
      {variant === 'pdp' && (
        <JaneGoldPdpCallout
          buttonLabel={buttonLabel}
          description={<CalloutDescription />}
          onLinkAccountClick={handleOnLinkAccountClick}
          onMenu={onMenu}
          title={pdpCalloutTitle}
        />
      )}
      {variant === 'cart' && (
        <JaneGoldCartCallout
          buttonLabel={buttonLabel}
          description={<CalloutDescription />}
          discountLabel={discountLabel}
          feeLabel={feeLabel}
          hasBreakdown={hasFeeBreakdown}
          headlessLoginUrl={headlessLoginUrl}
          onLinkAccountClick={handleOnLinkAccountClick}
          totalLabel={totalLabel}
          title={cartCalloutTitle}
        />
      )}
      {variant === 'checkout' && (
        <JaneGoldCheckoutCallout
          buttonLabel={buttonLabel}
          description={<CalloutDescription />}
          discountLabel={discountLabel}
          feeLabel={feeLabel}
          hasBreakdown={hasFeeBreakdown}
          headlessLoginUrl={headlessLoginUrl}
          onLinkAccountClick={handleOnLinkAccountClick}
          title={checkoutCalloutTitle}
          totalLabel={totalLabel}
        />
      )}
      {variant === 'checkoutSummary' && (
        <JaneGoldCheckoutSummaryCallout
          discountLabel={discountLabel}
          onLinkAccountClick={handleOnLinkAccountClick}
        />
      )}

      {openModal && !isGuestUser && (
        <BankLinkingModal
          open={openModal}
          onRequestClose={() => setOpenModal(false)}
          location={variant}
        />
      )}

      {openModal && authentication && isGuestUser && (
        <LogInModal
          errors={authentication.errors}
          location={variant}
          open={true}
          onAppleClick={authentication.onAppleClick}
          onGoogleClick={authentication.onGoogleClick}
          onRequestClose={() => {
            authentication.resetError();
            setOpenModal(false);
          }}
          onSignInSubmit={authentication.login}
          onCreateAccountSubmit={authentication.register}
          onForgotPasswordSubmit={authentication.resetPassword}
          showAppleLogin={authentication.showAppleLogin}
          showGoogleLogin={authentication.showGoogleLogin}
        />
      )}
    </Box>
  );
}
