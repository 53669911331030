import { useCallback, useEffect, useState } from 'react';

import {
  useAeropayUser,
  useConfirmJaneGoldUser,
  useCreateAeropayUser,
  useDeleteAeropayBankAccount,
  useSelectAeropayBankAccount,
} from '@jane/shared-ecomm/data-access';
import { EventNames, linkBankAccountEvent } from '@jane/shared-ecomm/tracking';
import type { CalloutVariants } from '@jane/shared-ecomm/types';
import { useJaneUser, useUpdateJaneUser } from '@jane/shared/data-access';
import type {
  AeropayBankAccount,
  AeropayWhiteLabelUser,
} from '@jane/shared/models';

import { JANE_ERRORS } from './failureScreen';
import type { MoreInfoFormData } from './moreInfoScreen';

export const SCREEN_NAMES = {
  loading: 'loading',
  moreInfo: 'more-info',
  moreInfoAeropay: 'more-info-for-aeropay',
  deleteBankAccountScreen: 'delete-bank-account',
  linkBank: 'link-bank',
  alreadyEnrolled: 'already-enrolled',
  welcomeBack: 'welcome-back',
  aeropay: 'aeropay',
  success: 'success',
  newAccountSuccess: 'new-account-success',
  promptLinkBank: 'prompt-link-bank',
  error: 'error',
};

type ScreenName = typeof SCREEN_NAMES[keyof typeof SCREEN_NAMES];

const checkAeropayStatus = (aeropayUser: AeropayWhiteLabelUser) => {
  if (aeropayUser.bank_accounts.length === 0) return SCREEN_NAMES.linkBank;

  if (aeropayUser.requires_gold_confirmation)
    return SCREEN_NAMES.alreadyEnrolled;

  return SCREEN_NAMES.welcomeBack;
};

export const useBankLinkingModalData = ({
  addNewAccount,
  deleteBankAccount,
  isJanePay,
  onRequestClose,
  location,
  setError,
  showPrompt,
}: {
  addNewAccount: boolean;
  deleteBankAccount: AeropayBankAccount | null;
  isJanePay: boolean;
  location?: CalloutVariants;
  onRequestClose: () => void;
  setError: (err: unknown) => void;
  showPrompt?: boolean;
}) => {
  const [screen, setScreen] = useState<ScreenName>('loading');
  const [loaded, setLoaded] = useState(false);
  const [needsMoreFlag, setNeedsMoreFlag] = useState(false);

  const {
    data: janeUser,
    isFetched: janeUserFetched,
    error: userError,
  } = useJaneUser();

  const { data: aeropayUser, error: aeropayUserError } = useAeropayUser({
    janeUserId: janeUser?.user?.id || null,
  });

  const { mutate: updateJaneUser } = useUpdateJaneUser();

  const { mutate: confirmJaneGold } = useConfirmJaneGoldUser({
    janeUserId: janeUser?.user?.id as number,
  });

  const { mutate: createAeropayUser } = useCreateAeropayUser({
    janeUserId: janeUser?.user?.id as number,
  });

  const { mutate: deleteAeropayBankAccount } = useDeleteAeropayBankAccount({
    janeUserId: janeUser?.user?.id as number,
  });

  const { mutate: selectAeropayBankAccount } = useSelectAeropayBankAccount({
    janeUserId: janeUser?.user?.id as number,
  });

  const handleDeleteBankAccount = () => {
    // track only if last bank account deleted
    if (aeropayUser?.bank_accounts.length === 1) {
      if (janeUser?.user?.email && janeUser?.user?.nickname) {
        linkBankAccountEvent({
          email: janeUser.user.email,
          event: EventNames.UnlinkedLastBankAccount,
          username: janeUser.user.nickname,
        });
      }
    }

    if (
      aeropayUser &&
      aeropayUser?.bank_accounts.length > 1 &&
      deleteBankAccount?.is_selected
    ) {
      // If the user tries to delete a selected bank account, we need to make sure that
      // another account has been auto-selected and only then proceed with deletion
      const nextBankAccount = aeropayUser?.bank_accounts.filter(
        (bankAccount) =>
          bankAccount.bank_account_id !== deleteBankAccount.bank_account_id
      )[0];
      selectAeropayBankAccount(
        {
          bankAccountId: nextBankAccount?.bank_account_id,
        },
        {
          onSuccess: () => {
            deleteAeropayBankAccount(
              {
                bankAccountId: deleteBankAccount?.bank_account_id,
              },
              {
                onError: (e: unknown) => {
                  console.error(`Delete Bank account Error: ${e}`);
                  setError(e);
                  setScreen(SCREEN_NAMES.error);
                },
              }
            );
            onRequestClose();
          },

          onError: (e: unknown) => {
            console.error(`Select Bank account Error: ${e}`);
            setError(e);
            setScreen(SCREEN_NAMES.error);
          },
        }
      );
    } else {
      deleteAeropayBankAccount(
        {
          bankAccountId: deleteBankAccount?.bank_account_id,
        },
        {
          onSuccess: () => onRequestClose(),
          onError: (e: unknown) => {
            console.error(`Delete Bank account Error: ${e}`);
            setError(e);
            setScreen(SCREEN_NAMES.error);
          },
        }
      );
    }
  };

  const handleClose = () => {
    setError(null);
    if (
      [
        SCREEN_NAMES.success,
        SCREEN_NAMES.newAccountSuccess,
        SCREEN_NAMES.alreadyEnrolled,
      ].includes(screen)
    ) {
      confirmJaneGold();

      // track only if first account linked
      if (
        aeropayUser?.bank_accounts.length === 1 &&
        screen !== SCREEN_NAMES.alreadyEnrolled
      ) {
        if (janeUser?.user?.email && janeUser?.user?.nickname) {
          linkBankAccountEvent({
            email: janeUser.user.email,
            event: EventNames.LinkedFirstBankAccount,
            isJanePay,
            source: location,
            username: janeUser.user.nickname,
          });
        }
      } else if (screen === SCREEN_NAMES.newAccountSuccess) {
        if (janeUser?.user.email && janeUser.user.nickname) {
          linkBankAccountEvent({
            email: janeUser.user.email,
            event: EventNames.LinkedNewBankAccount,
            isJanePay,
            source: location,
            username: janeUser.user.nickname,
          });
        }
      }
    }
    onRequestClose();
  };

  const handleCloseWithOptOut = (optOut?: boolean) => {
    if (optOut !== undefined) {
      updateJaneUser({
        first_name: janeUser?.user.first_name,
        promotions_opt_out: optOut,
      });
    }

    handleClose();
  };

  const handleSubmitInfo = useCallback(
    (data: MoreInfoFormData): void => {
      setScreen(SCREEN_NAMES.loading);
      const { firstName: first_name, lastName: last_name, phone } = data;

      const phoneNumber = typeof phone === 'number' ? phone.toString() : phone;

      if (screen === SCREEN_NAMES.moreInfoAeropay) {
        createAeropayUser(
          {
            email: janeUser?.user?.email as string,
            first_name,
            last_name,
            phone: phoneNumber,
          },
          {
            onSuccess: (data) => {
              const aeropayUser = data as AeropayWhiteLabelUser;
              if (aeropayUser.bank_accounts?.length) {
                setScreen(
                  aeropayUser.requires_gold_confirmation
                    ? SCREEN_NAMES.alreadyEnrolled
                    : SCREEN_NAMES.welcomeBack
                );
              } else {
                setScreen(SCREEN_NAMES.linkBank);
              }
            },
            onError: (e: unknown) => {
              if (janeUser?.user?.email && janeUser?.user?.nickname) {
                linkBankAccountEvent({
                  email: janeUser.user.email,
                  event: EventNames.LinkBankAccountFailed,
                  username: janeUser.user.nickname,
                });
              }
              console.error(`Create Aeropay User Error: ${e}`);
              setError(e);
              setScreen(SCREEN_NAMES.error);
            },
          }
        );
      }

      updateJaneUser(
        {
          first_name,
          last_name,
          phone: phoneNumber,
        },
        {
          onSuccess: () => {
            if (screen !== SCREEN_NAMES.moreInfoAeropay) {
              setScreen(
                checkAeropayStatus(aeropayUser as AeropayWhiteLabelUser)
              );
            }
          },
          onError: () => {
            if (janeUser?.user?.email && janeUser?.user?.nickname) {
              linkBankAccountEvent({
                email: janeUser.user.email,
                event: EventNames.LinkBankAccountFailed,
                username: janeUser.user.nickname,
              });
            }
            setError(JANE_ERRORS.update);
            setScreen(SCREEN_NAMES.error);
          },
        }
      );
    },
    [aeropayUser, screen]
  );

  useEffect(() => {
    if (janeUser?.user?.id && aeropayUser && !loaded && !deleteBankAccount) {
      const needsMoreInfo =
        !janeUser.user.first_name ||
        !janeUser.user.last_name ||
        !janeUser.user.phone;

      // If they do not have an aeropay account yet, we make one,
      // or request more info to do so
      if (!aeropayUser.user_id) {
        if (showPrompt) {
          setScreen(SCREEN_NAMES.promptLinkBank);
        } else if (needsMoreInfo) {
          setScreen(SCREEN_NAMES.moreInfoAeropay);
        } else {
          createAeropayUser(
            {
              email: janeUser.user.email as string,
              first_name: janeUser.user.first_name as string,
              last_name: janeUser.user.last_name as string,
              phone: janeUser.user.phone as string,
            },
            {
              onSuccess: (data) => {
                const aeropayUser = data as AeropayWhiteLabelUser;
                if (aeropayUser.bank_accounts?.length) {
                  setScreen(
                    aeropayUser.requires_gold_confirmation
                      ? SCREEN_NAMES.alreadyEnrolled
                      : SCREEN_NAMES.welcomeBack
                  );
                } else {
                  setScreen(SCREEN_NAMES.linkBank);
                }
              },
              onError: (e) => {
                setError(e);
                setScreen(SCREEN_NAMES.error);
              },
            }
          );
        }
      } else if (showPrompt) {
        setScreen(SCREEN_NAMES.promptLinkBank);
      } else if (needsMoreInfo) {
        setScreen(SCREEN_NAMES.moreInfo);
      } else {
        setScreen(
          addNewAccount
            ? SCREEN_NAMES.linkBank
            : checkAeropayStatus(aeropayUser as AeropayWhiteLabelUser)
        );
      }
      setLoaded(true);
      setNeedsMoreFlag(needsMoreInfo);
    }
  }, [
    addNewAccount,
    aeropayUser,
    createAeropayUser,
    deleteBankAccount,
    loaded,
    janeUser,
    janeUserFetched,
    showPrompt,
  ]);

  useEffect(() => {
    if (deleteBankAccount) {
      setScreen(SCREEN_NAMES.deleteBankAccountScreen);
    }
  }, [deleteBankAccount]);

  useEffect(() => {
    if (userError || aeropayUserError) {
      setError(userError ? JANE_ERRORS.janeUser : aeropayUserError);
      setScreen(SCREEN_NAMES.error);
    }
  }, [userError, aeropayUserError]);

  return {
    handleClose,
    handleCloseWithOptOut,
    handleSubmitInfo,
    handleDeleteBankAccount,
    needsMoreFlag,
    screen,
    setScreen,
  };
};
